<template>
  <div class="container kuan">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="dr_row">
        <p class="des"> <span class="font_bold">比如：</span> 小票5笔可直接截图分享给客户，100笔或更多的可用全截分享给客户</p>
      </div>

      <div class="dr_row">
        <h3>电脑版</h3>
        小票截图→点击全截→弹出下载窗口→选择下载路径→桌面或（C或D盘）→下载完成后→找到下载的文件→打开文件→核对内容后分享客户

      </div>
      <div class="dr_row">
        <h3>手机版</h3>
        小票截图→点击全截→弹出下载窗口→点击下载→完成后→选择 WPS office 打开（如果找不到WPS，在桌面找或手机屏幕往右滑动搜索中输入（WP）找到 WPS office）→点击进入后→点击打开找到PDF进入→找到下载的文件→打开文件→核对内容后分享客户

      </div>
      <div class="dr_row">
        <h3>奖虫版</h3>
        小票截图→点击全截→下载完成后→选择 WPS office 打开（如果找不到WPS，在桌面找或手机屏幕往右滑动搜索中输入（WP）找到 WPS office）→点击进入后→点击打开找到PDF进入→找到下载的文件→打开文件→核对内容后分享客户

      </div>

    </div>

  </div>

</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "qzsm",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      // 长龙 tab
      changelongActive: "1",
      baishige: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
      baishi: { title: "佰拾定位", arr: [] },
      baige: { title: "佰个定位", arr: [] },
      shige: { title: "拾个定位", arr: [] },
    };
  },
  watch: {
    pan(val) {
      this.baishige = 1;
    },
  },

  created() {},

  methods: {},
};
</script>
<style  scoped>
p.des {
  color: red;
  font-size: 15px;
}
.skin-blue .h2 {
  background: #2161b3;
}
.skin-red .h2 {
  background: #c2455c;
}
.skin-brown .h2 {
  background: #a18361;
}
.h2 {
  margin-top: 10px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.dr_row h3 {
  min-width: 50px;
  margin-left: 8px;
}
.dr_row {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #e4e4e4;
}
.listmain > .data > ul.custom_ul {
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}

.listmain .data ul li {
  width: 20%;
  height: 26px;
}
</style>